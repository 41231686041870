<template>
  <p class="buttons" style="display: flex;justify-content: space-around;">
    <button
      type="submit"
      class="button"
      @click="setAnswer(item, index)"
      :class="{ next: index === 0 }"
      v-for="(item, index) in items"
      :key="index"
    >
      <span>{{ item.text }}</span>
    </button>
  </p>
</template>

<script>
export default {
  name: "Continue",
  data: () => {
    return {
      data: {
        id: -1,
        otherValue: "",
        finish: false
      }
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    other: {
      type: Boolean,
      default: () => false
    },
    questionId: {
      type: Number,
      default: () => 0
    }
  },
  methods: {
    getAnswer() {
      let obj = {
        questionnaireId: +localStorage.getItem("questionareId"),
        questionId: this.questionId,
        answer: 1,
        optionId: this.data.id
      };
      if (this.data.finish) {
        obj.finish = this.data.finish;
      }
      return obj;
    },
    setAnswer(item, index) {
      this.data.id = item.id;
      if (index) {
        this.data.finish = true;
      }
    }
  }
};
</script>

<style scoped></style>
